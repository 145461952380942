import React from 'react';

const App = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Make the container full viewport height
    }}>
      <img
        src="/images/meret2.png" // Replace with the actual path to your 5000x2500 image
        alt="Resized Image"
        style={{
          width: '500px', // Set the desired width (500px)
          height: '250px', // Set the desired height (250px)
        }}
      />
    </div>
  );
};

export default App;
